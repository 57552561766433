import React from "react"
import Layout from "../components/Layout"

const GetStarted = () => (
  <Layout>
    <div className="getstarted">get started</div>
  </Layout>
)

export default GetStarted
